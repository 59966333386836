<template>
	<w-layout v-if="document && version" shrink align-center>
		<RotatePDFDocumentPageButton v-bind=tooltip :document="document" rotate-left :is-readonly="isReadonly" :is-editable="isEditable" :page="page" :version="version" />
		<RotatePDFDocumentPageButton v-bind=tooltip :document="document" rotate-right :is-readonly="isReadonly" :is-editable="isEditable" :page="page" :version="version" />
		<DeletePDFDocumentPageButton v-bind=tooltip :document="document" :is-readonly="isReadonly" :is-editable="isEditable" :page="page" :version="version" />
	</w-layout>
</template>

<script>
export default {
	name: 'DocumentPageActions',
	components: {
		DeletePDFDocumentPageButton: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages/Actions/DeletePDFDocumentPageButton')
		}),
		RotatePDFDocumentPageButton: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentPages/Actions/RotatePDFDocumentPageButton')
		})
	},
	props: {
		document: {
			type: Object,
			required: false,
			default: null
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: true
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		},
		page: {
			type: Number,
			required: false,
			default: 0
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		tooltip: {
			type: Object,
			required: false,
			default: () => ({
				bottom: true,
			})
		}
	}
}
</script>
